.wheel-detail .details-information {
  h2 {
    @include breakpoint (small only) {
      text-align: center;
      font-size:2rem;
    }
  }
  .price {
    font-size:1.25rem;
    .sale {
      text-decoration:line-through;
      &Price { 
        margin-left:.5rem;
      }
    }
    .sale-box {
      background-color:$yellow;
      color:$black;
      padding:.25rem;
      margin-left:.5rem;
      font-weight:400;
      font-size:1rem;
    }
  }
  .vehicle {
    &-reset {
      margin-left:auto;
    }
    button {
      margin-bottom:0;
      color:$orange;
      background-color:$orange2;
    }
  }
}