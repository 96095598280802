.wheel-spec {
    &-header {
        background-color: $primary-color;
        margin-bottom:0;
        font-weight:600;
        color:$black;
        letter-spacing:2px;
    }
    &-tabs > a {
        font-size: 1.2rem;
        color: #000;
        &:focus, &[aria-selected=true] {
            color: #fff;
            background-color: #999;
        }
        &:hover {
            color: #000;
        }        
    }
    &-panel {
        padding: 0;
    }
    &-btn {
        color: #c00;
        &:hover {
            color: #999;
        }
    }
  .button {
    margin-bottom:0;
    color:$black;
    border-radius: 3px;
    &:hover {
      color:$orange;
    }
  }
  .spec-sale {
    text-decoration: line-through;
    margin-right:.25rem;
    &-sign {
      background-color:$primary-color;
      font-weight:600;
      font-size:.8rem;
      text-transform: uppercase;
      padding:.25rem;
      white-space: nowrap;
      color:$black;
    }
  }
}