.text-overflow-button {
  display:none;
  padding-top:1rem;
  a {
    color:$gray;
    text-transform: uppercase;
    font-weight:500;
    font-size:.95rem;
    &:hover {
      color:$orange;
      .fas {
        color:$orange;
      }
    }
  }
  .fas {
    font-size:1.25rem;
    color:$yellow;
    margin-right:.25rem;
    transition: all .2s ease;
  }
}
@include breakpoint (small only) {
  .text-overflow-button {
    display:block;
  }
  .text-overflow {
      height:80px;
      display:block; 
      overflow:hidden;
      word-break: break-word;
      word-wrap: break-word;
  }
  
  .btn-overflow {
    display: none;
    text-decoration: none; 
  }
}

.product-detail {
  h3 {
    text-transform: uppercase;
  }
  .details-spec-description-container {
    padding-bottom:2rem;
    @include breakpoint (medium) {
      padding-bottom:0;
      border-right:1px solid $gray;
    }
  }
}

