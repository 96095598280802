/* ==========================================================================
   2. Header
   ========================================================================== */
header {
  background-color: rgba(0, 0, 0, 0.9);
  .top-bar {
    padding: 21px 0;
    a {
      font-size: 1.4rem;
      font-weight: 700;
      color: #000;
      text-transform: uppercase;
    }
    .top-bar-left {
      /*padding: 8px 0;
      padding-right: 2%;*/
    }
    .hp-btn-links a {
      font-size: 1.2rem;
      padding-left: 1.8rem;
      padding-right: 1.8rem;
      margin-left: 8px;
    }
  }
  .top-bar-title {
    img {
      max-width: 200px;
    }
  }
/*  .header-logo {
    width: 200px;
    position: relative;
    height: 1px;
    top: -5px;
    a {
      position: absolute;
      z-index: 10;
    }
  }*/
  // temp
  .pencil-bar-notification {
    //background-color:$red;
    background-color:$yellow;
    //color:$white;
    color:$black;
    text-align:center;
    font-weight:600;
    line-height:1;
    padding:.5rem 1rem;
    text-transform: uppercase;
    span {
      font-weight:900;
      text-decoration: underline;
    }
    a {
      text-decoration: underline;
      color:$white;
      &:hover {
        color:$gray5;
      }
    }
    &-image {
      background-color:$promotionYellow;
      text-align:center;
    }
  }
}
/* mobile */
.menu-icon.dark {
  &:hover:after,
  &:after {
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
  }
}

/* Media Queries */

@media screen and (max-width: 480px) {
  .menu > li:not(.menu-text) > a {
    padding: 0.7rem 0.5rem;
  }
  header .top-bar .top-bar-left {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1023px) {
  header {
    padding: 0;
    .menu.nav-social {
      display: block;
    }
    .top-bar-title {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      span {
        float: right;
      }
      img {
        width: 60%;
        margin-top: -10px;
      }
    }
    .top-bar {
      .top-bar-left,
      .top-bar-right {
        border-right: 0px;
        width: 100%;
      }
      .top-bar-right {
        padding: 10px 20px 20px;
      }
      .top-bar-left {
        li {
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #eeeeee;
        }
        ul ul div div {
          border-right: 0;
          margin: 0;
          &:last-child {
            border: none;
          }
        }
        .dropdown.menu .submenu li {
          padding-left: 0;
          padding-right: 0;
          border-bottom: 0;
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }
  header.fixed {
    position: inherit;
  }
  .menu-icon::after {
    background-color: #999999;
    box-shadow: 0 7px 0 #999999, 0 14px 0 #999999;
  }
  .menu > li {
    display: inherit;
  }
  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}

@media only screen and (max-width: 1212px) {
  header .top-bar a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1262px) {
  header .top-bar .top-bar-left {
    padding-right: 0;
  }
}

header {
  .top-bar {
    .header-contact {
      color:$white;
      text-transform: uppercase;
      font-family: $font-sans-serif;
      font-size:2rem;
      line-height:1;
      @include breakpoint (small only) {
        width:50%;
      }
      a {
        color:$white;
        font-size:1.6rem;
        @include breakpoint (small only) {
          font-size:.85rem;
          margin-left:1rem;
        }
      }
    }
    .header-cart {
      width:50%;
      a {
        color:$white;
        font-size:1.5rem;
      }
    }
  }
}

/* ==========================================================================
   3. Top bar
   ========================================================================== */

.sticky-container {
  z-index: 10;
}

header.main {
  background-color: white;
  box-shadow: 0px 5px 15px -5px black;
  position: relative;
  z-index: 10;
}

.basket-icon {
  color: $secondary-color;
  padding-right: 2px;
}

header {
  /*/// Pencil Navigation ///*/
  .pencil-top-bar {
    /*height: 51px;*/
    //background: $black;
    background-image:url('/images/utility/TOPO_PATTERN_1.svg');
    background-color:$black;
    background-size:cover;
    /*background: linear-gradient(
      -75deg,
      black 35%,
      $primary-color 35%,
      $primary-color 40%
    ); */ /* Standard syntax */
//    @media (min-width: 1024px) and (max-width: 1200px) {
//      background: linear-gradient(-75deg, black 33%, $primary-color 33%, $primary-color 40%);
//    }
    @include breakpoint (large) {
      .pencil-logo {
        width:23%;
      }
      .pencil-fitment {
        width:27%;
      }
    }
  }

  .pencil-top-announcement {
    color: white;
    height: 100%;
  }

  .pencil-top-menu .menu > li:not(.menu-text) > a {
    text-transform: uppercase;
    color: $medium-gray;
    font-family: $font-thirdary;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1.5 !important;
    margin: 0;
    padding: 1rem 0.8rem;
    @media (min-width: 1024px) and (max-width: 1200px) {
      padding: 1rem 0.3rem;
    }
    &:hover {
      color: white;
    }
  }

  .pencil-top-announcement {
    font-family: $font-primary;
    padding: 8px 0;
    white-space: nowrap;
  }

  .announcement-large {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 1.1rem;
    }
  }

  .announcement-small {
    font-size: 1rem;
    font-weight: 500;
    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 0.9;
    }
  }
  /*/// Main Navigation ///*/
  .top-bar {
    padding: 15px;
    @media only screen and (min-width: 64rem) {
      padding: 20px 0;
    }
    @media only screen and (max-width: 64rem) {
      .top-bar-right {
        margin-top: 1rem;
        border-top: 1px solid $medium-gray;
      }
    }

    .menu.icon-top > li > a {
      color: $dark-gray;
      @media screen and (max-width: 64rem) {
        text-align: left;
        font-size: 1.3rem;
        padding: 0.5rem;
        i {
          display: none;
        }
      }
    }
    .first-sub {
      left: 0 !important;
      right: 0 !important;
      top: 52px !important;
      border-top: 1px solid #ccc;
    }
    .header-logo {
      @media only screen and (max-width: 64rem) {
        top: 0;
      }
    }
  }
  .nav-background {
    background-color:$black;
    @include breakpoint (large) {
      background: linear-gradient(-60deg, $gray 33%, $primary-color 33%, $primary-color 40%);
    }
  }
}

.vertical-rule-left {
  @media only screen and (min-width: 64rem) {
    border-left: 1px $dark-gray solid;
  }
}

header .menu > li:not(.menu-text) > a {
  padding: 0.1rem;
  margin: 0 0.6rem;
  font-family: $font-sans-serif;
  @media only screen and (max-width: 64rem) {
    margin-left: 0;
  }
}

header .menu > li:not(.menu-text) > ul > li a {
  font-family: $font-body;
  font-weight:400;
  font-size:1.1rem;
}

// pencil top bar
header .pencil-top-bar {
  color:$white;
  input, select {
    border-radius: 5px;
    padding:.5rem;
  }
  .hp-search-title {
    text-transform: uppercase;
    font-weight:600;
    font-size:.8735rem;
    padding-bottom:.5rem;
  }
  @include breakpoint (medium up) {
      .column,.columns {
        padding-left:0.625rem;
        padding-right:0.625rem;
      }
    
  }
}

// pencil top bar - logo 
header .pencil-top-bar {
  .header-logo {
    padding-top:.625rem;
    padding-bottom:.625rem;
  }
}


// pencil top bar - year / make / model
header .pencil-top-bar {
  .pencil-header-top-center-container {
    padding-top:1.25rem;
    .button {
      padding:.75rem 0rem;
      color:$black;
      border-radius: 5px;
      width:100%;
      &:hover {
        background-color:$orange;
      }
      &:focus {
        background-color:$orange;
      }
    }
  }
}

// pencil top bar - search / account / cart
header .pencil-top-bar {
  .pencil-header-top-right-container {
    font-size:.8375rem;
    padding-top:1.25rem;
    a {
      font-size:.8375rem;
      color:$white;
      i {
        color:$primary-color;
        font-size:2rem;
        padding-right:.5rem;
      }
    }
    input {
      width:100%;
    }
    > div:nth-child(2) {
      border-left:1px solid $light-gray;
    }
    .pencil-header-top-right {
      a {
        display:flex;
        align-items:center;
        justify-content: center;
      }
      &-content {
        white-space:nowrap;
        &-title {
          text-transform: uppercase;
          font-weight:600;
          line-height:1;
        }
        &-subtitle {
          color:$light-gray;
          font-weight:500;
          font-size:.75rem;
        }
      }
      // warngin image 
      &-right {
        img {
          margin-top:-1rem;
        }
      }
    }
    #search-product-form {
      input {
        color:$black;
      }
    }
  }
}

/* ==========================================================================
   Nav Product Bar
   ========================================================================== */
.nav-product-bar {
  background-color:$black;
  a {
    font-family:$font-sans-serif;
    text-transform: uppercase;
    font-size:1.15rem;
    padding:.5rem 0;
    @include breakpoint (medium) {
      font-size:1.35rem;
      padding-bottom:0;
    }
    @include breakpoint (large) {
      font-size:1.9rem;
    }
    span {
      color:$white;
      transition: all .3s ease-in-out;
    }
    &:hover {
      color:$orange;
      span {
        color:$orange;
      }
    }
  }
  .row {
    > .column {
      background: linear-gradient(120deg, transparent 80%, $gray5 100%);
      border-bottom:1px solid $yellow;
      @include breakpoint (medium) {
        background: linear-gradient(90deg, transparent 80%, $gray5 100%);
        transform: skewX(-30deg);
        border-bottom:0;
      }
      a {
        @include breakpoint (medium) {
          transform:skewX(30deg);
        }
        display:flex;
        justify-content: center;
      }
    }
  }
}
.hp-header .nav-product-bar {
  background-image: url('/images/home/menu-background.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size:contain;
  
  a {
    font-size:1.5rem;
    height:75px;
    background-size:cover;
    padding:0;
    padding-top:.25rem;
    @include breakpoint (medium) {
      font-size:1.35rem;
      height:98px;
    }
    @include breakpoint (large) {
      font-size:1.9rem;
      height:150px;
    }
  }
  .row {
    > .column {
      background:transparent;
      //background: linear-gradient(100deg, $black 33%, $yellow 66%);
      //transform: skewX(-30deg);
      background-position: center;
      @include breakpoint (small only) {
        padding-left:0;
        padding-right:0;
        &:first-child {
          background-image: url('/images/home/header-suspension.jpg');
          background-size: 150%;
          background-position: center 10%;
          background-repeat: no-repeat;
        }
        &:nth-child(2) {
          background-image: url('/images/home/header-alloys.jpg');
        }
        &:last-child {
          background-image: url('/images/home/header-impact.jpg');
          background-position: 70% center;
          background-size: 225%;
        }
        a {
          background-color:rgba(0,0,0,.5);
          align-items:center;
        }
      }
    }
  } 
}
// mobile
/*
.hp-header {
  .accordion {
    margin:0;
  }
  .accordion-title {
    display:none;
  }
  .accordion-content {
    display:block;
    padding:0;
    border:0;
    @include breakpoint (small only) {
      float:left;
    }
  }
}*/


header .mobile-nav-product-bar {
  .accordion {
    margin:0;
  }
  .accordion-item > .accordion-title {
    font-weight:400;
  }
  .accordion-title {
    background-color:$black;
    font-family:$font-sans-serif;
    text-transform: uppercase;
    font-size:1.25rem;
    padding:.75rem;
    font-weight:400;
    color:$primary-color;
    border:0;
    border-bottom:1px solid $primary-color;
    @include breakpoint (medium) {
      font-size:1.35rem;
      padding-bottom:0;
      border:0;
    }
    @include breakpoint (large) {
      font-size:1.9rem;
      display:none;
    }
    span {
      color:$white;
      transition: all .3s ease-in-out;
    }
    &:hover {
      color:$orange;
      span {
        color:$orange;
      }
    }
  }
  .accordion-content {
    padding:0;
    border:0;
    @include breakpoint (large) {
      display:block;
    }
    &-ymm {
      float:left;
    }

  }
}
/* ==========================================================================
   2. Header
   ========================================================================== */
/* mobile */
.menu-icon.dark {
  &:hover:after,
  &:after {
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
  }
}

/* Media Queries */

@media screen and (max-width: 480px) {
  .menu > li:not(.menu-text) > a {
    padding: 0.7rem 0.5rem;
  }
  header .top-bar .top-bar-left {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1023px) {
  header {
    padding: 0;
    .menu.nav-social {
      display: block;
    }
    .top-bar-title {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      span {
        float: right;
      }
      img {
        width: 60%;
        margin-top: -10px;
      }
    }
    .top-bar {
      .top-bar-left,
      .top-bar-right {
        border-right: 0px;
        width: 100%;
      }
      .top-bar-right {
        padding: 10px 20px 20px;
      }
      .top-bar-left {
        li {
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #eeeeee;
        }
        ul ul div div {
          border-right: 0;
          margin: 0;
          &:last-child {
            border: none;
          }
        }
        .dropdown.menu .submenu li {
          padding-left: 0;
          padding-right: 0;
          border-bottom: 0;
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }
  header.fixed {
    position: inherit;
  }
  .menu-icon::after {
    background-color: #999999;
    box-shadow: 0 7px 0 #999999, 0 14px 0 #999999;
  }
  .menu > li {
    display: inherit;
  }
  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}

@media only screen and (max-width: 1212px) {
  header .top-bar a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1262px) {
  header .top-bar .top-bar-left {
    padding-right: 0;
  }
}


/* ==========================================================================
   2. Mobile
   ========================================================================== */
header {
  .top-bar {
    @include breakpoint (small only) {
      padding-left:0;
      padding-right:0;
      padding-bottom:0;
    }
  }
  .top-bar-title {
    display:flex;
    align-items:center;
    margin-right:0;
    padding:0;
    border-bottom:1px solid $primary-color;
    @include breakpoint (small only) {
      padding:.5rem 1rem;
    }
    
    > div {
      display:flex;
      justify-content: center;
      max-width:45%;
      width:100%;
      &:last-child {
        max-width:10%;
        justify-content: flex-end;
      }
    }
    .header-logo {
      img {
        width:100%;
      }
    }
    .header-contact {
      @include breakpoint (small only) {
        font-size:1rem;
      }
    }
    .header-mobile-button {
      .menu-icon {
        width:32px;
        height:26px;
        &:after {
          height:3px;
          background-color:$primary-color;
          box-shadow: 0 11px 0 $primary-color, 0 22px 0 $primary-color;
        }
      }
    }
  }
}

///// Mobile - Menu ///////
#responsive-menu {
  .top-bar-left {
    @include breakpoint (medium down) {
      background-color:$white; 
      li {
        border-bottom:0;
        a {
          padding-top:.75rem;
          padding-bottom:.75rem;
          font-size:1.1rem;
          margin-right:0;
        }
        ul {
          padding-top:0;
          position:static;
          li {
            border-top:0;
            a {
              font-family: $font-sans-serif;
              font-weight:600;
              padding-left:20px;
            }
          }
        }
      }
    }
    
  }
}


///// Mobile - Year Make Model ///////
.header-mobile-ymm-content {
  background-color:$black;
  float:left;
  width:100%;
  select {
    border-radius:5px;
  }
  .hp-search-title {
    color:$white;
    text-transform: uppercase;
    font-family:$font-sans-serif;
    padding-top:.25rem;
    padding-bottom:.25rem;
  }
  .app-guide.app-guide-border {
    > div {
      padding-left:.2rem;
      padding-right:.2rem;
      &:nth-child(2) {
        padding-left:.625rem
      }
      &:nth-child(4) {
        padding-right:.625rem;
      }
    }
  }
}

///// Mobile - Shopping (My Account / Cart) ///////
.header-mobile-shopping {
  i {
    float:right;
  }  
}

///// Header function /////////
/*header {
  .header-search-container {
    > div {
      max-height:0;
      overflow:hidden;
      transition: max-height 0.2s ease-out;
    }
  }
}*/
/*footer {
  .footer-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  .slideInDown,.slideOutUp {
    position:relative;
    z-index:-1;
  }
}*/