.home-replacement {
  background-color:$yellow;
  text-align:center;
  color:$black;
  text-transform: uppercase;
  line-height:1;
  padding-top:1.25rem;
  padding-bottom:1.25rem;
  .description {
    display:flex;
    justify-content: center;
    @include breakpoint (small only) {
      margin-bottom:1rem;
    }
    .icon {
      margin-right:.5rem;
      font-size:2rem;
      @include breakpoint (medium) {
        margin-right:1rem;
      }
    }
    .text {
      text-align:left;
    }
  }
  
  .title {
    font-family:$font-sans-serif;
    font-size:1.25rem;
    @include breakpoint (medium) {
      font-size:1.5rem;
    }
  }
  .subtitle {
    font-weight:800;
    font-size:.8rem;
    @include breakpoint (medium) {
      font-size:1rem;
    }
  }
  .button {
    background-color:$black;
    color:$white;
    border-radius:5px;
    margin-bottom:0;
    max-width:392px;
    display:inline-block;
    width:100%;
    .title {
      font-size:1.25rem;
    }
  }
}
