#financing {
  color:$black;
  margin-bottom:10rem;
  h1 {
    font-size:2rem;
    @include breakpoint (large) {
      font-size:4rem;
    }
  }
  h2 {
    font-size:2rem;
    @include breakpoint (large) {
      font-size:3rem;
    }
  }
  h3 {
    text-transform: uppercase;
  }
  p {
    font-size:1rem;
    @include breakpoint (large) {
      font-size:1.25rem;
    }
  }
  a {
    font-weight:800;
  }
  span {
    font-family:$font-sans-serif;
    color:$black;
    &.name {
      color:$primary-color;
    }
    &.italic {
      font-style: italic;
      font-family:$font-body;
      font-weight:700;
    }
  }
  // header
  .page-header {
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:4.15rem;
    }
  }
  // description
  .description {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:0;
    }
    .hide-for-large {
      br {
        @include breakpoint (medium down) {
          display:none;
        }
      }
    }
    img.hide-for-large {
      float:right;
      max-width:50%;
      padding-left:1rem;
    }
  }
  // features
  .features {
    .subtitle {
      text-align:center;
    }
    // features top
    .features-top {
      position:relative;
      align-items:center;
      margin-bottom:1rem;
      @include breakpoint (large) {
        display:flex;
      }
      h3 {
        padding-left:10px;
        padding-right:10px;
        font-size:1.5rem;
        margin:0;
      }
      .logo {
        text-align:center;
        margin-bottom:1rem;
        @include breakpoint (large) {
          text-align:unset;
          margin-bottom:0;
        }
        img {
          max-width:220px;
        }
      }
      .statement {
        padding-left:.5rem;
      }
      .features-bar {
        background-color:$primary-color;
        padding-top:.5rem;
        padding-bottom:.5rem;
        margin-bottom:2rem;
        text-align:center;
        @include breakpoint (large) {
          display:flex;
          text-align:unset;
          padding-top:1.25rem;
          padding-bottom:1.25rem;
        }
        h3 {
          @include breakpoint (small only) {
            font-size:1rem;
            padding-left:.5rem;
            padding-right:.5rem;
          }
        }
        > div {
          border-right:2px solid $orange;
          text-align:center;
          display:inline-flex;
          @include breakpoint (large) {
            text-align: unset;
            border-right-width: 5px;
          }
          &:last-child {
            border-right:0;
          }
        }
        &:after {
          @include breakpoint (large) {
            background-color:$primary-color;
            content:'';
            position:absolute;
            height:64px;
            width:100%;
            left:0;
            right:0;
            z-index:-1;
            margin-top:-20px;
          }
        }
      }
    }
    // features bottom
    .features-bottom {
      margin-bottom:2rem;
      .features-item {
        background-color:$primary-color;
        border-radius: 15px;
        padding:1rem;
        margin-bottom:1rem;
        @include breakpoint (large) {
          margin-bottom:0;
        }
      }
      .title {
        text-align:center;
        border-bottom:2px solid $orange;
        margin-bottom:1rem;
        h3 {
          margin-bottom:.5rem;
        }
      }
      .features-item-info {
        text-align: center;
      }
    }
  }
  // process
  .process {
    .title {
      text-align:center;
      @include breakpoint (large) {
        text-align:unset;
      }
    }
    .section {
      display:flex;
      margin-bottom:1rem;
      &:nth-child(odd) {
        background-color:$yellowLight;
        .content {
          text-align:right;
          @include breakpoint (large) {
            padding-left:4rem;
            padding-right:0;
          }
        }
      }
    }
    .step {
      font-family:$font-sans-serif;
      text-align:center;
      width:100px;
      font-size:5rem;
      @include breakpoint (large) {
        width:200px;
        font-size:10rem;
        line-height:1;
      }
    }
    .content {
      width:100%;
      padding:1rem;
      @include breakpoint (large) {
        padding-right:4rem;
      }
    }
  }
}