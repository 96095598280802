#giftguide {
  .page-header {
    img {
      width:100%;
    }
  }
  .page-information {
    text-align:center;
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:5rem;
    }
    h4 {
      text-transform: uppercase;
      @include breakpoint (large) {
        font-size:2.75rem;
        letter-spacing:.1rem;
      }
    }
    p {
      font-weight:500;
    }
  }
  .section {
    margin-bottom:5rem;
    h2 {
      color:$white;
      text-transform: uppercase;
      margin-bottom:0;
      font-size:2rem;
      letter-spacing:.1rem;
      @include breakpoint (large) {
        font-size:3rem;
        letter-spacing:.25rem;
      }
    }
    .title {
      text-align:center;
      background-color:$black;
      margin-bottom:4rem;
      padding:1rem 0;
    }
    img {
      margin-bottom:1rem;
      transition: all .3s ease-in-out;
    }
    .button {
      transition: all .3s ease-in-out;
    }
    a {
      display:block;
      text-align:center;
      &:hover {
        img {
          transform: scale(1.025);
        }
        .button {
          background-color:$black;
          color:$white;
        }
      }
    }
  }
}