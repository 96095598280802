.wheel-gallery {
  @include breakpoint (small only) {
    padding-bottom:1rem;
  }
  &__header {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.105;
    margin: 0;
    @include breakpoint(small only) {
      margin-top: 1rem;
    }
  }
}

.wheel-galleryList {
  display: inline-block;
  margin: 0;
  width:100%;
  /*@include breakpoint(small only) {
    margin-left: 0.5rem;
  }*/
  > div {
    padding: 0;
  }
  &__item {
    .wheel-galleryImage {
      margin-right:.25rem;
      @include breakpoint (medium) {
        margin-right:.5rem;  
      }
    }
    &:nth-child(2n) {
      .wheel-galleryImage {
        margin-right:0;
        margin-left:.25rem;
        @include breakpoint (medium) {
          margin-left:.5rem;
        }
      }
    }
  }
  /*&__item {
    list-style: none;
    float: left;
    width: 31%;
    height: auto;
    margin-right: 2%;
    margin-top: 0.5rem;
    &:nth-child(n + 7) {
      display: none;
    }
    &--display:nth-child(n + 7) {
      display: block;
    }
  }*/
}

.wheel-galleryImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /*margin-right:.5rem;*/
  transition:all .3s ease-in-out;
/*  @include breakpoint(small only) {
    width: 95px;
    height: 60px;
  }*/
  img {
    visibility: hidden;
  }
  &:hover {
    opacity:.4;
  }
}

@include breakpoint(small only) {
  .wheel-galleryTxt {
    font-size: 0.8rem;
  }
}
