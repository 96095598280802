.wheel-angles {
  padding-bottom:1rem;
  .slick-track {
    margin-top:0;
  }
  .slick-slide {
    border:1px solid $black;
    width:92px!important;
    margin-right:5px;
    padding:.25rem;
    opacity:.4;
    transition:all .3s ease-in-out;
    float:none;
    display:inline-block;
    &:hover {
      opacity:1;
    }
    &.slick-current {
      opacity:1;
    }
  }
  .details-360 {
    margin-bottom:0;
    img {
      margin-right:0;
      width:100%;
    }
  }
}