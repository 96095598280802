#gallery-top {
  padding-top:5.5rem;
}
.gallery-content {
  padding-top:3rem;
  padding-bottom:3rem;
}
.gallery-breadcrumbs {
  font-size:.875rem;
  text-transform: capitalize;
  font-weight:800;
  padding-bottom:2.5rem;
  color:$gray;
  a {
    color:$gray;
    &:hover {
      color:$orange;
    }
  }
}
.gallery-title {
  padding-bottom:2rem;
  h1 {
    font-size: 2rem;
    margin: 0;
  }
}
#gallery-main-image {
    margin-bottom: 1rem;
    img {
        width: 100%;
    }
}
.gallery-thumb {
    margin-bottom: 1rem;
}
#gallery-details {
    margin-bottom: 2rem;
}
#gallery-thumbs .slick-dots {
    bottom: 0!important;
    position: relative;
    margin: 0;
}




.gallery-details {
  font-weight:500;
  color:$gray;
  > div {
    padding-bottom:1.5rem;
    > div {
      padding:1.25rem 1.25rem;
      border:1px solid $lighter-gray;
      border-top:0;
      font-size:1.15rem;
      &:first-child {
        text-transform: uppercase;
        padding-top:.65rem;
        padding-bottom:.65rem;
        font-size:1rem;
        font-weight:600;
      }
    }
  }
  
  // build
  .gallery-build {
    .build-title {
      background-color:$yellow;
      border-color:$yellow;
      color:$black;
    } 
    .build-section-title {
      color:$black;
      text-transform: uppercase;
    }
    .build-accessory, .build-wheel {
      display:flex;
      padding:1rem 1rem;
      a {
        color:$orange;
        &:hover {
          color:$yellow;
        }
      }
      &-container-image {
        max-width: 30%;
        padding-right:1rem;
      }
      &-container-information {
        font-size:1.13rem;
      }
      &-item {
        line-height:1;
        margin-bottom:.5rem;
      }
    }
  }
  
  // Contact
  .gallery-contact {
    .contact-title {
      background-color:$gray2;
      color:$white;
    }
    .contact-info { 
      text-transform: capitalize;
      a {
        color:$gray;
        display:flex;
        align-items: center;
        &:hover {
          color:$orange;
        }
      }
      i {
        font-size:2rem;
      }
      .contact-icon {
        width:32px;
        height:32px;
        text-align:center;
        margin-right:1rem;
      }
      &-dealer {
        padding-bottom:1rem;
      }
    }
  }
}

// gallery bottom
// build details
.gallery-build-details {
  padding:2rem;
  border: 1px solid $gray4;
  h3 {
    text-transform: uppercase;
  }
  ul {
    margin-bottom:0;
  }
}