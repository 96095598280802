.form {
  &-information {
    text-align:center;
    padding-top:3rem;
    padding-bottom:3rem;
    h3 {
      text-transform: uppercase;
      @include breakpoint (medium) {
        font-size:2rem;
      }
    }
    p {
      font-weight:300;
      color:$black;
      @include breakpoint (medium) {
        font-size:1.15rem;
      }
    }
    a {
      font-weight:900;
    }
  }
  
  
  &-content {
    background-color:$background-gray;
    padding-top:4rem;
    padding-bottom:5rem;
    input, select, textarea {
      border-color:$lighter-gray;
      margin-bottom:1.25rem;
    }
    select {
      max-width:260px;
      border-radius:7px;
    }
    form {
      > div {
        padding-bottom:2rem;
      }
    }
    .form-content-title {
      border-bottom:1px solid $lighter-gray;
      margin-bottom:1rem;
      h4 {
        margin-bottom:.5rem;
      }
    }
    .disclaimer,.required-fields {
      font-size:.8735rem;
      font-weight:400;
    }
    .disclaimer {
      &-contact {
        font-weight:600;
      }
    }
    .required-fields {
      color:$red;
      font-weight:600;
    }
  }
}




////////// Feedback //////////
.form.feedback {
  .survey {
    label {
      margin-bottom:.75rem;
    }
  }
}