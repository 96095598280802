.home-about {
  text-align:center;
  img {
    width:100%;
  }
  &__container {
    color:$white;
    /*> div {
      position:relative;
    }
    > div:last-child {
      position:absolute;
    }*/
  }
  &__content {
    padding-top:5%;
    background-image:url('/images/home/about.jpg');
    background-position:center;
    background-size:cover;
    padding-bottom:15%;
  }
  &__title {
    font-size:5.5rem;
    font-family:$font-sans-serif;
    text-transform: uppercase;
    line-height:1;
    span {
      color:$primary-color;
    }
    @include breakpoint (small only) {
      font-size:1.5rem;
    }
  }
  &__subtitle {
    font-weight:300;
    text-transform: uppercase;
    font-size:2.5rem;
    @include breakpoint (small only) {
      font-size:1rem;
      font-weight:500;
    }
  }
  &__text {
    color:$black;
    max-width:800px;
    margin:0 auto;
    padding-top:1rem;
    @include breakpoint (small only) {
      font-size:.9375rem;
      font-weight:400;
      color:$white;
    }
  }
  &__btn {
    font-size:8rem;
    @include breakpoint (small only) {
      font-size:4rem;
    }
  }
}
