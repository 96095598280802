#gallery {
	.hover-list {
      .column {
        margin-bottom:2rem;
      }
		.photo {
			position: relative;
			display: block;
			padding: 0 1px 1px 0;
			/*&:before {
				position: absolute;
				z-index: 1;
				display: block;
				width: 100%;
				height: 100%;
				content: '';
				-webkit-transition-timing-function: linear;
				transition-timing-function: linear;
				-webkit-transition-duration: .3s;
				transition-duration: .3s;
				-webkit-transition-property: all;
				transition-property: all;
				background: rgba(255, 255, 255, 0);
			}
			&:after {
				font-size: 1.1rem;
				position: absolute;
				z-index: 2;
				top: 50%;
				display: block;
				width: 90%;
				margin: -1em 5% 0;
				padding-bottom: 2em;
				content: attr(title);
				-webkit-transition-timing-function: linear;
				transition-timing-function: linear;
				-webkit-transition-duration: .2s;
				transition-duration: .2s;
				-webkit-transition-property: all;
				transition-property: all;
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				opacity: 0;
				color: #d40000;
				filter: alpha(opacity=0);
			}
			&:hover:before {
				background: rgba(255, 255, 255, .9);
			}
			&:hover:after {
				opacity: 1;
			}*/
          &-overlay {
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            background-color:rgba(0,0,0,0.7);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity:0;
            color:$white;
            font-size:1.25rem;
            font-weight:900;
            transition:all .3s ease-in-out;
            @include breakpoint (small only) {
              font-size:.7rem;
            }
            > div {
              padding:.25rem 0;
              line-height:1;
              text-align:center;
              &.photo-overlay-divider {
                padding:1rem 0;  
              }
            }
            &-accessory, &-offset, &-suspension {
              font-size:1.5rem;
              @include breakpoint (small only) {
                font-size:.9rem;
              }
            }
            &-divider {
              height:4px;
              width:20%;
              position: relative;
              &:after {
                content:'';
                background-color:$yellow;
                height:4px;
                position:absolute;
                top:43%;
                bottom:0;
                left:0;
                right:0;
              }
            }
            &-moreinfo {
              font-size:1rem;
              text-transform: uppercase;
            }
          }
          &:hover {
            .photo-overlay {
              opacity:1;
            }
          }
		}
		.video:after {
			font-weight: 300;
			color: #fefefe;
		}
		.video:hover:before {
			background: rgba(223, 61, 61, .9);
		}
	}
}



#gallery .gallery-submit-container {
  margin-top:1rem;
  margin-bottom:1rem;
  display:flex;
  align-items: center;
  justify-content: space-around;
  background-color:$yellow;
  text-transform: uppercase;
  font-family:$font-body-condensed;
  font-size:1.5rem;
  font-weight:800;
  padding:1rem;
  @include breakpoint (small only) {
    flex-direction:column;
  }
  a {
    color:$black;
    &:hover {
      color:$orange;
    }
  }
  .gallery-submit-description {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:75%;
    @include breakpoint (small only) {
      line-height:1;
      width:100%;
      margin-bottom:1rem;
      .gallery-submit-text {
        padding-left:.5rem;
        font-size:1rem;
      }
    }
  }
  .gallery-submit-button {
    @include breakpoint (small only) {
      width:100%;
      text-align:center;
    }
    a {
      background-color:$gray;
      color:$white;
      padding:1rem;
      font-weight:600;
      border-radius: 10px;
      font-size:1.25rem;
      @include breakpoint (small only) {
        display:block;
        padding:.35rem;
        border-radius:5px;
      }
      &:hover {
        background-color:$black;
        color:$orange;
      }
    }
  }
  
}

