#fc {
  font-weight:400;
  .fc-fixed-button-right .fc-btn-action {
    background-color:$yellow!important;
    background:$yellow!important;
    color:$black!important;
    text-shadow: none;
  }
  #fc-cart {
    header {
      background-color:transparent;
    }
    .fc-transaction__discounts .fc-subtotal--row .fc-subtotal__label, .fc-transaction__discounts .fc-subtotal--row .fc-subtotal__value {
      color:$orangeCart;
      font-weight:500;
    }
    
    .fc-action--checkout--button, .fc-button--subscription-cancel, .fc-button--submit {
      border-color:$yellow;
      background:$yellow;
      border-radius: 0;
      color:$black;
      text-shadow:none;
      &:hover {
        border-color:$yellowDark;
        background:$yellowDark;
      }
      .fc-svg-icon path {
        fill:$black!important;
      }
    }
  }
  
  .notice {
    padding-left:15px;
    padding-right:15px;
  }
}