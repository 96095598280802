.details-information {
  font-size:1rem;
  h2 {
    font-family:$font-sans-serif;
    font-size:1.75rem;
    text-transform: uppercase;
    margin-bottom:0;
  }
  > div {
    padding-bottom:.5rem;
  }
  .label {
    background-color:transparent;
    font-weight:700;
    text-transform:capitalize;
    color:$black;
    font-size:inherit;
    padding-left:0;
  }
  .details-brand-logo {
    padding-bottom:1.5rem;
  }
  .title {
    padding-bottom:1.5rem;
  }
  .instock {
    font-family:$font-sans-serif;
    text-transform: uppercase;
    font-weight:600;
    font-size:1.25rem;
    &.orange {
      color:$orange;
    }
    &.green {
      color:$green;
    }
  }
  .part-number .label {
    text-transform: uppercase;
  }
  .price {
    font-size:1.25rem;
    .sale {
      text-decoration:line-through;
      &Price { 
        margin-left:.5rem;
      }
    }
    .sale-box {
      background-color:$yellow;
      color:$black;
      padding:.25rem;
      margin-left:.5rem;
      font-weight:400;
      font-size:1rem;
    }
  }
  .quantity {
    display:flex;
    align-items: center;
    padding-bottom:1rem;
    input {
      max-width:2rem;
      max-height:1.5rem;
      padding:0 .25rem;
      margin-bottom:0;
    }
  }
  .vehicle {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    border-top:1px solid $gray;
    border-bottom:1px solid $gray;
    padding-top:1rem;
    padding-bottom:1rem;
    margin-bottom:1rem;
    &-icon {
      background-color:$black;
      display:flex;
      justify-content: center;
      align-content: center;
      border-radius: 50px;
      width:100%;
      max-width:50px;
      height:50px;
      margin-right:1rem;
      i {
        color:$yellow;
        display: flex;
        align-items: center;
        font-size:2rem;
      }
    }
    &-information {
      font-weight:800;
      font-size:2rem;
      color:$black;
      line-height: 1;
    }
  }
  .shipping {
    padding-bottom:1rem;
  }
  .button {
    color:$black;
    &:hover {
      color:$orange;
    }
  }
  .financing-options {
    padding-top:1rem;
    margin-bottom:1rem;
  }
}