////////// General //////////////////////
.products {
    
}


////////// CTA //////////////////////
.products-cta {
    padding-top:2rem;
    padding-bottom:2rem;
    &__content {
    background-size:100%;
    background-position:center;
    display:flex;
    align-items: center;
    justify-content: center;
    padding-top:32%;
    padding-bottom:32%;
    position:relative;
    border-right:1px $white solid;
    transition: all .5s ease;
    @include breakpoint (small only) {
      padding-top:20%;
      padding-bottom:20%;
      border-right:0;
      border-bottom:1px solid $white;        
    }
    &:before {
      position: absolute;
      content: '';
      background: rgba(0,0,0,.7);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-transition: background-color 1s;
      transition: background-color 1s;
    }
    &:hover {
      background-size:110%;
      background-color:rgba(0,0,0,.8);
      > div {
        color:$secondary-color;
      }
    }
    > div {
      z-index:1;
      color:$white;
      transition:all .5s ease;
    }
  }
  .cta-content-01 {
    background-image:url('/images/home/cta-02.jpg');
    @include breakpoint (small only) {
      border-top:1px solid $white;
    }
  }
  .cta-content-02 {
    background-image:url('/images/home/cta-01.jpg');
  }
  &__icon {
    padding-right:1.2rem;
    @include breakpoint (small only) {
      text-align:right;
      padding-right:.5rem;
      img {
        max-width:80%;
      }
    }
  }
  &__title {
    font-family:$font-sans-serif;
    font-weight:700;
    text-transform: uppercase;
    font-size:2.1rem;
    line-height:1;
    letter-spacing:1px;
    @include breakpoint (small only) {
      font-size:1.5rem;
    }
  }
  &__subtitle {
    font-weight:400;
    @include breakpoint (small only) {
      font-size:.8735rem;
    }
  }
}