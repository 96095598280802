.text-banner {
  padding: 3rem 0;
  &__header {
    color: $dark-gray;
    font-size: 2.7rem;
    font-weight: 700;
  }
}

.text-banner--primary {
  background-color: $primary-color;
  font-size: 1.4rem;
  font-weight: 600;
  color: $black;
  padding: 1rem 0;
  &__header {
    display: inline-block;
    color: $black;
    margin: 0;
    font-size:2.75rem;
    font-weight:100;
    @include breakpoint (small only) {
      font-size:1.25rem;
    }
  }
}
