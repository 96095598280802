.home-blog {
  .left {
    a {
      display:block;
      height:100%;
      background-position: center;
      background-size:cover;
    }
    img {
      width:100%;
      opacity:0;
    }
  }
  &-content {
    padding:5%;
    @media screen and (min-width:1400px) {
      padding:10% 18%;
    }
    > div {
      margin-bottom:2rem;
    }
  }
  .right {
    background-color:$black;
    display:flex;
    align-items: center;
    justify-content: center;
    .title,.button {
      font-family: $font-sans-serif;
    }
    .article-title,.article-excerpt {
      font-family:$font-body-condensed;
      color:$white;
    }
    .title {
      text-transform: uppercase;
      color:$primary-color;
      font-size:2rem;
      line-height:1;
      @include breakpoint (medium) {
        font-size:3rem;
      }
    }
    .article-title {
      text-transform: uppercase;
      color:$white;
      font-size:1.5rem;
      line-height:1;
      @include breakpoint (medium) {
        font-size:2.25rem;
      }
    }
    .article-excerpt {
      font-size:1.125rem;
      line-height:1.5;
      @include breakpoint (medium) {
        font-size:1.25rem;
        line-height:1.7;
      }
    }
    .button {
      border-radius: 10px;
      font-size:1rem;
      padding:.75rem 1.5rem;
      font-weight:500;
      @include breakpoint (medium) {
        font-size:1.5rem;
      }
      &:hover {
        background-color:$gray3;
      }
    }
  }
}