.related-products {
  &-header {
    background-color:$black;
    h2 {
      color:$yellow;
      font-family:$font-body;
      font-weight:400;
      font-size:1.5rem;
    }
  }
}