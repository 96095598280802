/* 
=======================================
Table of Contents
=======================================
*/

@charset 'utf-8';

// ********** FOUNDATION ********** //
@import 'settings';
@import 'foundation';
@include foundation-everything(false);

// ********** MOTION UI ********** //
@import 'motion-ui';
@include motion-ui-transitions;
@include motion-ui-animations;

// ********** SLICK SLIDER ********** //
@import 'slick-theme.scss';
@import 'overrides/slick.scss';
@import 'slick.scss';

// ********** MAGNIFIC POPUP ********** //
@import 'main';

// ********** GENERAL ********** //
@import 'general';
@import 'utility';
@import 'header';
@import 'footer';

// ********** UTILITY ********** //
@import 'utility/breadcrumbs';
@import 'utility/foxycart';
@import 'overrides/foxycart';

// ********** HOME ********** //
@import 'home/hero';
@import 'home/pencil-brands';
@import 'home/cta';
@import 'home/banners';
@import 'home/about';
@import 'home/replacement';
@import 'home/blog';

// ********** MISCELLANEOUS ********** //
@import 'vehicle-bar';
@import 'nav-search';

// ********** PRODUCTS ********** //
@import 'products/categories';
@import 'products/filter';
@import 'products/listing';
@import 'products/details';

// ********** WHEELS ********** //
@import 'wheel/wheels';
@import 'wheel/wheel';
@import 'wheel/filter';
@import 'wheel/stage';
@import 'wheel/specs';
@import 'wheel/info';
@import 'wheel/cta';
@import 'wheel/details-media';
@import 'wheel/view-on-vehicle';
@import 'wheel/vehicle-gallery';
@import 'wheel/spec-tabs';
@import 'wheel/angles';
@import 'wheel/related';
@import 'wheel/recommended';

// ********** TIRES ********** //
@import 'tire/tire';
@import 'tire/info';
@import 'tire/cta';
@import 'tire/specs';

// ********** ACCESSORIES ********** //
@import 'accessory/accessory';
@import 'accessory/cta';
@import 'accessory/bar';
@import 'accessory/info';
@import 'accessory/popup';

// ********** GALLERY ********** //
@import 'gallery/header';
@import 'gallery/landing';
@import 'gallery/detail';

// ********** CONTENT ********** //
@import 'content/contact';
@import 'content/page';
@import 'content/news';
@import 'content/products-landing';
@import 'content/form';
@import 'content/blog';
@import 'content/guides';
@import 'content/dealers';
@import 'content/financing';
@import 'content/promotions';
@import 'content/giftguide';
@import 'content/catalogs';
@import 'content/exp';

// ********** POPUPS ********** //
@import 'popups/package';
@import 'popups/accessory';
@import 'popups/gallery-quote';
@import 'popups/fitment-check';
