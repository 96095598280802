#upsell-wheels-popup {
  max-width:532px;
  display:flex;
  flex-direction:column;
  align-items:center;
  border:20px solid $black;
  h4 {
    font-size:2.5rem;
    line-height:.8;
    letter-spacing:.05rem;
    span {
      font-size:1.75rem;
    }
  }
  .upsell-wheels-image {
    margin-bottom:2rem;
  }
  .upsell-wheels-addtl-promo {
    text-align:center;
    align-items: center;
    margin-bottom:1rem;
    h4 {
      text-transform: uppercase;
      line-height:.6;
      span {
        font-size:1rem;
        font-family:$body-font-family;
        font-weight:600;
      }
    }
  }
  .popup-content {
    /*width:100%;
    display:flex;
    > button {
      width:100%;
      margin:0 1px;
      &:first-child {
        margin-left:0;
      }
      &:last-child {
        margin-right:0;
      }
    }*/
    max-width:400px;
    button {
      width:100%;
      margin-bottom:1rem;
      &#upsell-open-fitment {
        font-size:1.25rem;
      }
      &#upsell-close-popup {
        background-color:transparent;
        text-decoration: underline;
        font-weight:400;
        font-size:1rem;
        color:$black;
        margin-bottom:0;
        &:hover {
          color:$orange;
        }
      }
    }
  }
  #app-guide-kit-to-wheel {
    //margin-top:2rem;
    //padding-top:2rem;
    //border-top:1px solid $black;
    width:100%;
    max-width:400px;
  }
}