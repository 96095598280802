.hp-hero {
  position: relative;
  background-color:$black;
  .hp-slider {
    position: relative;
    img {
      width: 100%;
    }
    .slider-caption {
      position: absolute;
      width: 100%;
      bottom: 25%;
      z-index: 10;
      color: #ffffff;
      @include breakpoint (small only) {
        bottom:0;
      }
    }
  }
  .slick-slide {
    position: relative;
  }
  .slick-slider {
    margin-bottom: 0px;
  }
  .parallax {
    margin-bottom: 0;
    .img:after {
      position: inherit;
    }
  }
  .slider-caption h4 {
    font-size: 1.3rem;
    text-transform: uppercase;
  }
  .slider-content {
    color:$white;
    @include breakpoint (small only) {
      text-align:center;
    }
    &-title {
      font-size:7rem;
      line-height:1;
      font-weight:900;
      @include breakpoint (small only) {
        font-size:2rem;
        letter-spacing:1px
      }
    }
    &-subtitle {
      font-weight:300;
      font-family:$font-body;
      font-size:2.5rem;
      @include breakpoint (small only) {
        font-size:1.1rem;
      }
    }
    &-link {
      padding-top:1rem;
      @include breakpoint (small only) {
        padding-top:.5rem;
      }
      a {
        padding:.75rem 1rem;
        background-color:$primary-color;
        color:white;
        font-size:2rem;
        letter-spacing:2px;
        border-radius:5px;
        color:$black;
        @include breakpoint (small only) {
          letter-spacing:1px;
          font-size:1.1rem;
          padding:.5rem .75rem;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .hp-hero .row {
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 40.063em) and (max-width: 800px) {
  .hp-hero {
    .slider-caption h4 {
      font-size: 2rem;
    }
    .side-bars {
      font-size: 1rem;
    }
    .scroll-arrow .fa {
      font-size: 2rem;
    }
  }
}

@media only screen and (min-width: 800px) {
  .hp-hero {
    .slider-caption h4 {
      font-size: 3rem;
    }
    .side-bars {
      font-size: 1.2rem;
      &:before,
      &:after {
        display: block;
      }
      .scroll-arrow .fa {
        font-size: 3rem;
      }
    }
  }
}

.shop-by-vehicle-hero-overlay {
  background-image: url('/images/home/hero-bg.jpg');
  background-size: cover;
  border-top: 1px solid $medium-gray;
  bottom: 0;
  color: white;
  font-family: $font-primary;
  width: 100%;
  z-index: 1;
  @include breakpoint (medium up) {
    position: absolute;
    background-image: none;
  }

  .title {
    text-align: center;
  }

  h3 {
    color: white;
    font-family: $font-primary;
    font-size: 2rem;
    padding-bottom: 1rem;
  }

  hr {
    border-bottom: 1px solid #555;
  }
  .shop-by-vehicle-bg {
    padding: 1rem 0 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    @include breakpoint (medium up) {
      padding: 3rem 0;
      padding-bottom: 2rem;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.vehicle-select select {
  font-weight: normal;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}

.ask-our-experts {
  text-align: center;
  @include breakpoint (medium up) {
    padding-top: 15px;
    text-align: left;
  }
  em {
    font-size: 1.3rem;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    @include breakpoint (medium up) {
      padding-bottom: 0;
    }
  }
  &__btn {
    margin-bottom: 0;
  }
}
