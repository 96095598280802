.guides {
  ul {
    list-style: none;
    margin:0;
  }
  a {
    color:$gray;
    &:hover {
      color:$orange;
    }
  }
  span {
    font-weight:600;
  }
  h3 {
    margin-bottom:0;
    text-transform: uppercase;
  }
  p {
    font-size:1.1rem;
    margin-bottom:0;
  }
  .guide-make {
    padding-top:1rem;
    padding-bottom:1rem;
    border-bottom:1px solid $gray4;
    margin-bottom:1rem;
  }
  .guide-item {
    color:$gray4;
    margin-bottom:.5rem;
  }
  .page-description {
    padding-top:2rem;
    padding-bottom:1rem;
    text-align:center;
    color:$black;
    font-weight:400;
  }
  .page-content {
    padding-top:1rem;
  }
  
  
  .guide-item {
    display:none;
  }
  
  
  .list-overflow-button-container {
    margin-bottom:2rem;
    a {
      color:$gray;
      text-transform: uppercase;
      font-weight:500;
      font-size:.95rem;
      &:hover {
        color:$orange;
        .fas {
          color:$orange;
        }
      }
    }
    .fas {
      font-size:1.25rem;
      color:$yellow;
      margin-right:.25rem;
    }
  }

  // search
  .guide-search {
    padding-bottom:2rem;
    border-bottom:1px solid $gray4;
  }
  .manual-search {
    display:flex;
    justify-content:center;
    
    .row {
      
    }
    input {
      max-width:200px;
      margin-right:.25rem;
    }
    .button {
      color:$black;
      &:hover {
        background-color:$orange;
      }
    }
  }
  .manual-results {
    .manual-result {
      .heading {
        text-align:center;
        font-weight:600;
        margin-bottom:1rem;
        color:$black;
      }
      a {
        display:flex;
        background-color:$yellow;
        margin-bottom:1rem;
        padding:.75rem 1.75rem;
        text-align:center;
        font-size:1.25rem;
        font-weight:500;
        line-height:1.2;
        color:$black;
        &:hover {
          background-color:$orange;
          color:$white;
          i {
            color:$white;
          }
        }
        i {
          color:$orange;
          margin-right:1rem;
          font-size:2rem;
          transition: all .3s ease-in-out;
        }
        .subtitle {
          font-size:.9rem;
          font-weight:400;
        }
        &.not-available {
          pointer-events: none;
          background-color:$gray3;
          color:$white;
          i {
            color:$white;
          }
        }
      }
    }
  }
}