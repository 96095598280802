#catalogs {
  p {
    font-weight:400;
    color:$black;
  }
  h3 {
    span {
      text-transform: uppercase;
    }
  }
  .content {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:4rem;
    }
  }
  .instruction {
    text-align:center;
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:2rem;
    }
    p {
      font-style: italic;
      text-transform: uppercase;
      font-weight:500;
    }
  }
  .catalogs-thumbnails {
    margin-bottom:4rem;
    float:left;
    @include breakpoint (large) {
      margin-bottom:6rem;
    }
    .image {
      margin-bottom:1rem;
    }
    .title {
      text-align:center;
      text-transform: uppercase;
      font-weight:500;
      color:$black;
      line-height:1;
      font-size:1rem;
      @include breakpoint (large) {
        font-size:1.25rem;
      }
    }
  }
/*
  .row > .column {
    &:nth-child(5), &:nth-child(4), &:nth-child(3) {
      padding-top:2rem;
      @include breakpoint (large) {
        padding-top:6rem;
      }
    }
  }*/
  .catalog-item {
    text-align:center;
    margin-bottom:4rem;
    @include breakpoint (large) {
      margin-bottom:6rem;
    }
  }
}