.recommended-products {
  border:1px solid $black;
  h5 {
    color:$white;
    text-transform: uppercase;
    margin:0;
    line-height:1;
    letter-spacing:0;
    font-size:1.45rem;
  }
  &-title {
    padding:1rem;
    background-color:$black;
  }
  &-item {
    display:flex;
    padding:1rem;
    @include breakpoint (large) {
      padding:2rem;
    }
    > div {
      width:100%;
    }
  }
  .recommended-product {
    &-image {
      width:33.3333%;
    }
    &-name {
      text-transform: uppercase;
      font-weight:700;
      color:$black;
      margin-bottom:1rem;
      font-size:1.15rem;
    }
    &-options {
      margin-left:1rem;
      .bottom {
        display:flex;
      }
      input,button {
        margin-bottom:0;
      }
      select {
        font-weight:500;
      }
      button {
        background-color:transparent;
        border:1px solid $black;
        margin-left:1rem;
        letter-spacing:.25rem;
        font-weight:500;
        width:100%;
        color:$black;
        &:hover {
          background-color:$yellow;
          color:$black;
        }
      }
      label {
        text-transform: capitalize;
        text-align:center;
        position:relative;
        display:flex;
        justify-content: center;
        max-width:72px;
        width:100%;
        span {
          position: absolute;
          background-color:$white;
          line-height: 1;
          top: -0.45rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
      input {
        max-width:72px;
        text-align:center;
      }
    }
  }
}