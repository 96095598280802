.wheel-filter {
  text-align: center;
  &__header {
    font-size: 1rem;
    display: inline-block;
    margin: 0;
  }
  &__dropdown {
    display: inline-block;
    margin: 0 0.5rem;
    @include breakpoint (small only) {
      margin:0;
      width:100%;
    }
    
  }
  &__btn {
    margin: 0;
  }
}

.filters {
  &__header {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    padding: 30px 0;
    text-transform: uppercase;
  }
  &__select {
    background: #3f4851;
    padding: 1.5rem 0.8rem 0.8rem;
    select {
      text-transform: uppercase;
      margin: 0;
      background-color: #e1e1e1;
      font-weight: 500;
    }
  }
  &__button {
    background-color: #e1e1e1;
    color: #000;
    font-weight: 500;
    max-height: 2.45rem;
    width: 100%;
  }
}

#wheel-filter-header {
  .accordion-title {
    color:$yellow;
    text-transform: uppercase;
    margin-bottom:1rem;
  }
  @include breakpoint (small only) {
    padding-left:1rem;
    padding-right:1rem;
    .accordion {
      .padded.extra {
        padding:0;    
      }
      &-title {
        padding:1rem;
        text-transform: capitalize;
        font-size:1rem;
        border:1px solid $gray;
      }
    }
    #wheel-filter-search {
      padding:0;
      border:0;
    }
    .button {
      width:100%;
    }
    select {
      padding-left:1rem;
      padding-top:1rem;
      padding-bottom:1rem;
      height:auto;
      border-color:$gray;
      background-position: right -.5rem center;
    }
  }
}