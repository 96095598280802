.wheel-specs {
  margin-top: 2rem;
  &__header {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  &__details {
    margin-left: 0;
    list-style: none;
    font-weight: 600;
  }
}

.detail-spec-tabs {
  border: none;
  &-content {
    border: none;
  }
  &-panel {
    padding: 0;
  }
  li {
    display: inline-block;
    width: 50%;
    text-align: center;
  }
  a {
    text-transform: uppercase;
    padding: 0.6rem 0;
    font-size: 1rem;
    font-weight: 400;
    background-color: #ddd;
    color: #666;
    &[aria-selected=true] {
      background-color: $primary-color;
      color: #fff;
      font-weight: 500;
    }
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}


.bp-toggle-btn {
  color: $primary-color;
}