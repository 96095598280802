/* ==========================================================================
   1. General (Foundation Overrides)
   ========================================================================== */
// variables
$primary-color:#ffcc00;
$secondary-color:#ff9c00;

$yellow:#ffcc00;
$yellowDark:#d0a000;
$yellowLight:#fff4c9;
$orange:#ff9c00;
$orange2:#fdebd5;
$orangeCart:#fb5900;
$red:#d40000;
$green:#16c739;

$gray:#333333;
$gray1:#4d4d4d;
$gray2:#666666;
$gray3:#999999;
$gray4:#cccccc;
$gray6:#222222;

$gray54: #545454;
$gray5: #555555;
$gray9: #999999;
$grayED: #ededed;

$light-gray:$gray3;
$lighter-gray:$gray4;
$background-gray:$grayED;

$promotionYellow: #ffc324;


$font-sans-serif:'Russo One',sans-serif;
$font-body:'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-body-condensed:'Roboto Condensed', sans-serif;


body {
  font-family:$font-body;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:$font-sans-serif;
}

h1,
h2,
h3 {
  color: #000000;
  text-transform: uppercase;
  line-height: 1;
}

h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: -1px;
}

h1 {
  font-size: 5.44rem;
}

h2 {
  font-size: 4.18rem;
}

h3 {
  font-size: 3.1rem;
}

h5 {
  font-size: 2.09rem;
}

h6 {
  font-size: 1.15rem;
}

.h1 {
  font-size: 1.5rem;
}

.h2 {
  font-size: 1.8rem;
  font-weight: 700;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #999;
}

body {
  font-family: $body-font-family;
  color: $medium-gray;
  font-weight: 300;
  line-height: 1.7;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 1rem;
  color: $black;
}
h1 {
  font-size:4.5rem;
}
h2 {
  font-size: 3.25rem;
}
h3 {
  font-size:2.15rem;
}
h4 {
  font-size:1.75rem;
}
h5 {
  font-size:1.25rem;
}

a {
  color: $primary-color;
  transition:all .2s ease;
  &:hover {
    color: $secondary-color;
  }
  &:focus {
    color:$primary-color;
  }
}
anchor-color-hover
ul,
ol {
  margin-right: 0;
  margin-bottom: 0;
}
table {
  min-width: 100%;
  margin-bottom: 0;
  border: 0;
}

table tr td,
table thead tr th {
  text-align: center;
}

table thead tr th {
  color: #fefefe;
  background: #707070;
}

.bg-img {
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  display: none !important;
}

.product-paragraph {
  color: #000;
  margin-bottom: 0.5rem;
  text-align: left;
  margin-right: 1rem;
}

.info {
  &--limit {
    line-height: 1.2em;
    overflow: hidden;
    margin-bottom: 0;
  }

  &--height {
    height: 6em;
  }
}

.parallax {
  position: relative;
  overflow: hidden;
  text-align: center;
  h1,
  h2 {
    position: relative;
    font-size: 3rem;
    top: 25%;
    padding-top:70px;
    padding-bottom:70px;
    @include breakpoint (small only) {
      font-size:1.75rem;
      padding-top:25px;
      padding-bottom:25px;
    }
  }
  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .bg-top {
      background-position: center top;
    }
  }
  .img:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    opacity: 0.4;
    background: #000;
  }
}

.white-popup {
  background: $white;
  margin: 20px auto;
  max-width: 400px;
  padding: 20px;
  position: relative;
  width: auto;

  .popup-header {
    font-size: 1.2rem;
  }
  .button-group {
    margin-bottom: 0;
    overflow: auto;
  }
}
.no-results {
  color: $black;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  color: white;
  display: none;
  &--nav {
    display: block;
    pointer-events: none;
    z-index:100;
  }
}
.primary {
  color: $primary-color;
}
.primary-bg {
  background-color: $primary-color;
}

.primary-btn {
  border-radius: 4px;
  padding: 0.8rem 1.9rem;
  margin: 0;
  font-weight: 700;
  a {
    color: $white;
  }
}

.red-bg {
  background-color: #d40000;
}

.black-font {
  color: #333;
}

.red {
  color: #d40000;
}

.white {
  color: #fefefe;
}

.button {
  font-weight: bold;
  background-color: $yellow;
  color:$black;
  &:focus {
    background-color:$green;
  }
}

.no-margin {
  margin-bottom: 0;
}

.button.secondary {
  background-color: #e7e7e7;
  color: #323232;
}

.black,
.button.black {
  background-color: #000000;
}

.button,
.text-uppercase {
  text-transform: uppercase;
}

.button:hover {
  background-color: $black;
  color:$white;
}

.button-group .button {
  padding-right: 0;
  padding-left: 0;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-heavy {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.g-recaptcha {
  margin-bottom: 0.5rem;
  display: inline-block;
}

#captchaCheck {
  float: left;
  margin-left: 1rem;
  text-transform: uppercase;
}

.padded {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padded.extra {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padded.short {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.padded-left {
  padding-left: 0.5rem;
}

.padded-right {
  padding-right: 0.5rem;
}

.container-padding--b {
  padding-bottom: 4rem;
}

.accordion .accordion-navigation > .content {
  padding-right: 0;
  padding-left: 0;
}

.accordion-item:first-child > :first-child {
  font-weight: 700;
}

.anchor {
  position: relative;
  top: -3rem;
  display: block;
  visibility: hidden;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.menu.simple > li > a {
  padding: 0;
}
.menu > li:not(.menu-text) > a {
  padding: 1rem;
}
.fixed {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
}
.title-bar,
.top-bar {
  padding: 0;
}
.top-bar,
.top-bar ul {
  background-color: transparent;
}
.top-bar ul.is-dropdown-submenu {
  background-color: rgba(255, 255, 255, 1);
  border: 0px;
}

.dropdown.menu .is-dropdown-submenu-parent.is-down-arrow > a::after {
  right: 0px;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  padding-right: 1rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a:after {
  border-color: $secondary-color transparent transparent;
  right: -6px;
  top:7px;
  border-left-width:8px;
  border-right-width:8px;
  border-top-width:8px;
  border-bottom-width:8px;
}

.dropdown.menu.vertical > li.opens-right > a::after {
  border-color: transparent transparent transparent $yellow;
  right: 5px;
}

.dropdown.menu > li > a:hover,
.dropdown.menu > li > ul li a:hover,
.dropdown.menu > li.is-active > a {
  color: $secondary-color;
}

.dropdown.menu > li > ul li a {
  padding: 0.5rem 0rem;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 154%;
  /*min-width: 280px;*/
  padding-bottom: 20px;
}

address {
  font-style: normal;
}

#instafeed .column {
  padding: 1px;
}

@media only screen and (min-width: 40.063em) {
  .int.site-content .h2 {
    font-size: 2.6rem;
  }
}

/********* VIDEO *********/

#videos {
  margin-top: 3rem;
  a:hover {
    color: #666;
  }

  a::before {
    background: rgba(255, 255, 255, 0);
  }

  p {
    font-size: 1.6rem;
  }

  #next-button {
    background-color: #fff;
  }

  #video-container .column a {
    position: relative;
    display: block;
  }
}

// ********** POPUP ********** //
.white-popup--wide {
  max-width: 700px;
}

// ********** HOVER ITEMS ********** //
.hover-item-link {
  display: block;
  padding: 1rem 0;
}

.hover-item {
  position: relative;
  display: block;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  text-align: center;
  &:hover {
    .hover-item__img {
      opacity: 1;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
  &__header {
    line-height: 1.2;
    margin-bottom: 0;
    font-size: 1.2rem;
  }
  &__header,
  h6 small {
    color: #545454;
  }
  &__img {
    -webkit-transition: -webkit-all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2.7rem;
  }
  h2 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}

/* ==========================================================================
   4. CMS Content
   ========================================================================== */

.page-content {
  padding-top:1rem;
  padding-bottom:7rem;
  @include breakpoint (medium) {
    padding-top:3rem;
  }
  @include breakpoint (large) {
    padding-top:5.5rem;
  }
}

/* ==========================================================================
   5. Brands
   ========================================================================== */
.selected-vehicle-container {
  padding: 0;
}
#wheel-brands {
  .filters {
    h5 {
      margin-bottom: 0;
      font-family: $font-thirdary;
    }
  }
  .filter_button {
    border-radius: 0;
    font-size: 1rem;
    font-family: $font-thirdary;
  }
  .filter_select {
    select {
      margin-bottom: 0;
    }
  }
}

/* ==========================================================================
   5. Brand (Product) Landing page
   ========================================================================== */
#wheels {
  /*//// filter ////*/
  .product-filter {
    padding-top: 0;
    background-color: #3f4851;
    h5 {
      margin-bottom: 0;
      font-family: $font-thirdary;
    }
    .filter_button {
      border-radius: 0;
      font-size: 1rem;
      font-family: $font-thirdary;
    }
    .filter_select {
      select {
        margin-bottom: 0;
      }
    }
  }
}

.pagination .current {
  background-color:$yellow;
}

/* ==========================================================================
   6. Tire Details
   ========================================================================== */
.brand-details {
  a {
    margin-bottom: 2rem;
    display: block;
  }
}



/* ==========================================================================
   7. FOUC - flash of unstyled content
   ========================================================================== */
.no-js {
  display:none;
}
.sticky-container {
  transition: all .2s ease-in-out;
}


.do-it{
  //background-color:orange;
  &:after {
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.9);
    z-index: 99999;
    position: absolute;
  }
}