.page {
    padding-bottom: 50px;
    &__content {
        padding-top: 50px;
        h1, h2 {
            text-transform: initial;
            font-family:"Roboto Condensed";
        }
    }
}
.page-header {
    font-size: 2.5rem;
    font-weight: 700;
    font-family:"Roboto Condensed";
}

#page {
  a {
    color:$gray;
  }
  .accordion-item {
  }
  .accordion-content {
    color:$medium-gray;
    padding:2rem;
  }
  .accordion-title {
    border:0;
    border-bottom:1px solid $gray;
  }
  .form-content {
    input[type="file"] {
      margin-bottom:0;
    }
    .notes {
      font-size:.75rem;
      margin-bottom:1.25rem;
    }
  }
}