#exp {
  color:$black;
  p {
    font-weight:400;
  }
  h3,h4,h5,h6 {
    text-transform: uppercase;
  }
  h3,h4 {
    @include breakpoint (large) {
      letter-spacing:.05rem;
    }
  }
  h3 {
    font-size:2.75rem;

  }
  h4 {
    @include breakpoint (large) {
      font-size:2.5rem;
    }
  }
  span {
    font-family:$font-sans-serif;
    text-transform: uppercase;
  }
  
  .page-header {
    img {
      width:100%;
    }
  }

  .home-brands {
    h3 {
      font-size:1.95rem;
      text-transform: capitalize;
    }
  }

  .page-content {
    padding-top:2rem;
    &:last-child {
      padding-bottom:2rem;
      @include breakpoint (medium) {
        padding-bottom:5.5rem
      }
    }
  }

  .page-information {
    text-align:center;
    margin-bottom:2rem;
    p {
      font-weight:500;
    }
  }
  .section {
    margin-bottom:5rem;
    h2 {
      color:$white;
      text-transform: uppercase;
      margin-bottom:0;
      font-size:2rem;
      letter-spacing:.1rem;
      @include breakpoint (large) {
        font-size:3rem;
        letter-spacing:.25rem;
      }
    }
    .title {
      text-align:center;
      background-color:$black;
      margin-bottom:4rem;
      padding:1rem 0;
    }
    img {
      margin-bottom:1rem;
      transition: all .3s ease-in-out;
    }
    .button {
      transition: all .3s ease-in-out;
    }
    a {
      display:block;
      text-align:center;
      &:hover {
        img {
          transform: scale(1.025);
        }
        .button {
          background-color:$black;
          color:$white;
        }
      }
    }
  }

  .exp-section {
    // about 
    &-about {
      margin-bottom:2rem;
      h3 {
        color:$yellow;
        @include breakpoint (large) {
          font-size:4rem;
        }
      }
      p {
        font-size:1.25rem;
        @include breakpoint (large) {
          font-size:1.5rem;
        }
      }
    }


    // systems
    &-systems {
      background-color:$black;
      margin-bottom:2rem;
      padding:1rem 0;
      @include breakpoint (large) {
        padding:2rem 0;
      }
      h3 {
        color:$white;
      }
      .title {
        text-align: center;
      }

      a {
        border-radius:15px;
        border:3px solid $yellow;
        background-color:$white;
        text-align:center;
        display:block;
        padding:.5rem;
        .name,.subtitle {
          font-family:$font-sans-serif;
          text-transform: uppercase;
          font-size:1.5rem;
          color:$black;
          line-height:1;
          letter-spacing:.05rem;
        }
        .image {
          margin-bottom:.5rem;
        }
        .subtitle {
          border-radius:15px;
          background-color:$yellow;
          margin-bottom:.5rem;
          padding:.5rem;
          &.inv {
            background-color:$black;
            color:$yellow;
            margin-bottom:0;
          }
        }
      }
    }


    // detailed
    &-detailed {
      margin-bottom:2rem;
      .title {
        text-align:center;
      }
      .image {
        margin-bottom:2rem;
      }
    }


    // slider
    &-slider {  
      margin-bottom:2rem;
      @include breakpoint (large) {
        margin-bottom:4rem;
      }
      h5 {
        margin-bottom:0;
      }    
      .title-bar,.footer-bar {
        background-color:$yellow;
        text-align:center;
        padding-top:.25rem;
        padding-bottom:.25rem;
      }
      .footer-bar {
        background-color:$black;
        h5 {
          color:$white;
        }
      }
      .slick-slider {
        margin:0;
      }
      .vehicles-arrow {
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        background: transparent;
        color: transparent;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        background-color:$white;
        color:$gray3;
        z-index:10;
        font-size:1.5rem;
        transition:all .2s ease-in-out;
        @include breakpoint (medium) {
          width:40px;
          height:40px;
          font-size:2rem;
        }
        @include breakpoint (large) {
          width:80px;
          height:80px;
          font-size:3rem;
        }
        &:hover {
          color:$white;
          background-color:$gray3;
        }
      }
      .vehicles-next {
        right:50px;
        @include breakpoint (medium) {
          right:80px;
        }
        @include breakpoint (large) {
          right:210px;
        }
      }
      .vehicles-prev {
        left:50px;
        @include breakpoint (medium) {
          left:80px;
        }
        @include breakpoint (large) {
          left:210px;
        }
      }
      .slick-slide {
        margin-left:.25rem;
        margin-right:.25rem;
      }
      img {
        width:100%;
      }
    }

    // products 
    &-products {
      h6 {
        margin-bottom: 0;
        font-family:$font-body;
        font-weight:600;
        line-height:1.25;
        letter-spacing:0;
      }
      .product-item {
        text-align:center;
        border-bottom:1px solid $gray4;
        margin-bottom:2rem;
        min-height: 400px;
      }
      .product-image {
        min-height:200px;
        padding-bottom:2rem;
        position:relative;
        img {
          max-height:200px;
        }
        .in-stock-image {
          max-width:50px;
          position: absolute;
          top:0;
          left:0;
        }
      }
      .product-name {
        min-height:40px;
        /*max-height:40px;*/
      }
      .product-notes,.product-price {
        color:$black;
        padding-bottom:1rem;
      }
      .product-notes {
        font-weight:600;
        line-height:1.25;
      }
      .product-number {
        color:$gray3;
        font-weight:800;
        line-height:1.5;
      }
      .product-price {
        margin-top:1rem;
      }
    
      // specials
      .pricing {
        color:$gray54;
        font-weight:600;
        .price,.sale-price {
          font-weight:400;
        }
        .price {
          text-decoration: line-through;
        }
        .sale-price {
          margin-left:.25rem;
        }
        .sale-box {
          background-color:$yellow;
          color:$black;
          padding:.25rem;
  
          display:inline-block;
          padding:.25rem .5rem;
          line-height:1.5;
          margin-bottom:.5rem;
        }
      }
    }
  }

  // footer
  .footer {
    @include breakpoint (medium) {
      margin-bottom:2.5rem;
    }
    &-bar {
      background-color:$yellow;
      text-align:center;
      @include breakpoint (medium) {
        text-align:left;
      }
    }
    p {
      font-family:$font-sans-serif;
      text-transform: uppercase;
      margin-bottom:0;
      color:$black;
      font-size:1.25rem;
    }
    .column {
      display:flex;
      align-items:center;
    }
    .logo {
      @include breakpoint (medium) {
        position:relative;
        width:25%;
        bottom:70px;
        img {
          position:absolute;
        }
      }
      @include breakpoint (large) {
        width:15%;
        bottom:90px;
      }
      img {
        max-width:180px;
        width:100%;
      }
    }
    .description {
      margin-left:1rem;
    }
    .mobile-display {
      .logo {
        margin:0 auto;
        margin-bottom:2rem;
        img {
          max-width:125px;
        }
      }
    }
  }
}