#promotions {
  h2 {
    text-transform: uppercase;
  }
  h6 {
    text-transform: none;
  }
  .promotion-item {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:4rem;
    }
    a {
      display:inline-block;
      margin-bottom:1rem;
    }
    .title {
      text-align:center;
    }
  }
}

#promotion {
  h5 {
    text-transform: uppercase;
  }
  h6 {
    text-transform: none;
  }
  .banner, .description {
    margin-bottom:2rem;
  }
  .images {
    margin-bottom:1rem;
    img {
      margin-bottom:1rem;
    }
  }

  // cooler rebate 2022
  &.cooler-rebate {
    .download {
      text-align:center;
      border-bottom:1px solid $gray9;
      margin-bottom:2rem;
      padding-bottom:2rem;
      .button {
        padding:.5rem 2rem;
        border-radius:.5rem;
        border:2px solid $black;
        font-family:$font-sans-serif;
        font-size:1.25rem;
        box-shadow:5px 5px 5px $gray9;
        @include breakpoint (medium) {
          font-size:1.75rem;
          padding:1rem 3rem;
        }
      }
    }
  }
  // ultimate stocking stuffer 2022
  &.ultimate-stocking-stuffer {
    h3 {
      margin-bottom:2rem;
    }
    ul,ol {
      margin-left:2.25rem;
    }
    .page-header {
      img {
        width:100%;
      }
    }
    .page-description {
      margin-bottom:2rem;
      h4 {
        margin-bottom:4rem;
      }
    }
    .excerpt {
      border-bottom: 1px solid $gray9;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
    }
  }
}



#promotions.promotions-2023 {
  h1 {
    text-transform: uppercase;
  }
  p {
    font-size:1.25rem;
  }
  img {
    width:100%;
  }
  .page-content {
    a {
      text-decoration: underline;
      color:$black;
      font-weight:500;
      &:hover {
        color:$yellow;
      }
      &.promotional-item {
        display:block;
        margin-bottom:2rem;
      }
    }
  }
  .title,.excerpt {
    text-align:center;
    margin-bottom:3rem;
  }
  .title {
  
  }
  .excerpt {
  
  }
  .terms {
    h5 {
      text-transform: uppercase;
    }
    p {
      font-size:1rem;
    }
  }
}