.tire-specs {
  margin-top: 2rem;
  &__header {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  &__details {
    color: #000;
    margin-left: 0;
    list-style: none;
    font-weight: 600;
  }
}
