#wheels {


  // specials
  &.wheel-specials {
    .info {
      color:$gray54;
      font-weight:600;
      .spec-info {
        line-height:1.5;
      }
      .price,.sale-price {
        font-weight:400;
      }
      .price {
        text-decoration: line-through;
      }
      .sale-price {
        margin-left:.25rem;
      }
      .sale-box {
        background-color:$yellow;
        color:$black;
        padding:.25rem;
      }
    }
  }
}