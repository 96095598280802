.home-brands {
  background: $secondary-color;
  background: linear-gradient(-60deg, $black 65%, $secondary-color 35%, $gray 40%);
  padding-top:1.5rem;
  padding-bottom:1.5rem;
  color:$white;
  text-transform: uppercase;
  @media screen and (min-width:1500px) {
    background: linear-gradient(-60deg, $black 60%, $secondary-color 35%, $gray 40%);
  }
  @include breakpoint (small only) {
    background-image:none;
    background-color:$gray;
    padding-top:1rem;
    padding-bottom:1rem;
  }
  h3 {
    font-size:1.95rem;
    margin-bottom:0;
    color:$white;
    @include breakpoint (small only) {
      font-size:1.5rem;
      padding-bottom:.25rem;
      
/*      &:after {
        content:'';
        border-bottom:1px solid $black;
        max-width:50%;
        width:360px;
        margin:0 auto;
        display:block;
        margin-bottom:.75rem;
        padding-bottom:.75rem;
      }*/
    }
  }
  &__content {
    display:flex;
    align-items: center;
    @include breakpoint (small only) {
      display:block;
      text-align: center;
    }
  }
  &__list {
    ul {
      margin:0;
      list-style: none;
      li {
        float:left;
        @include breakpoint (small only) {
          float:none;
          display:inline-block;
        }
        a {
          border-right:1px solid $secondary-color;
          color:$white;
          font-family: $font-sans-serif;
          padding:0 1rem;
          font-size:1.5rem;
          @include breakpoint (small only) {
            border-right:2px solid $black;
            font-size:.8375rem;
            padding-right:.5rem;
            padding-left:.25rem;
          }
          &:hover {
            color:$primary-color;
            @include breakpoint (small only) {
              color:$black;
            }
          }
        }
        &:last-child {
          a {
            border-right:0;
            @include breakpoint (small only) {
              border-right:0;
            }
          }
        }
      }
    }
  }
}