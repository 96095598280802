.cta-buttons {
  margin:0;
  border-top:1px solid $gray;
  border-bottom:1px solid $gray;
  padding-top:1.5rem;
  padding-bottom:1.5rem;
  margin-top:.5rem;
  margin-bottom:1rem;
  li {
    margin-bottom:.75rem;
    @include breakpoint (medium) {
      display:inline-block;
      margin-right:.75rem;
      margin-bottom:0;
    }
    .button {
      color:$black;
      width:100%;
      padding:.85rem 1rem;
      font-size:.9rem;
      @include breakpoint (medium) {
        max-width:220px;
      }
      &:hover {
        background-color:$black;
        color:$orange;
      }
      &:focus {
        background-color:$gray3;
      }
      &.cta-parts {
        background-color:$gray4;
      }
      &.cta-see-on-vehicle {
        background-color:$gray1;
        color:$white;
      }
    }
  }
}