.packaging {
  background-color: #F5F5F5;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  position: relative;
  width: auto;

  .popup_header {
    font-size: 1.2rem;
  }
  .button-group {
    margin-bottom: 0;
    overflow: auto;
  }
  .returnTxt {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
}