/// Footer Divider 
.footer-divider {
  height:12px;
  background: linear-gradient($gray6, $gray2, $gray6);
}
/// Footer Bar
footer {
  background-image:url('/images/utility/TOPO_PATTERN_1.svg');
  background-color:$black;
  background-size:cover;
  .accordion-item {
    background-color:$black;
    color:$white;
  }
  a {
    color:$primary-color;
  }
  .footer-bar {
    padding-top:2rem;
    padding-bottom:2rem;
    display:flex;
    align-items: center;
    justify-content: center;
    @include breakpoint (small only) {
      display:block;
      padding-top:1rem;
      padding-bottom:1rem;
    }
    > div {
      max-width:33%;
      width:100%;
      @include breakpoint (small only) {
        max-width:none;
        text-align:center;
      }
    }
    // title 
    &-title {
      display:flex;
      align-items:center;
      justify-content: center;
      font-weight:600;
      @include breakpoint (small only) {
        padding-bottom:1rem;
      }
    }
    // social media
    &-social {
      ul {
        display:table;
        width:65%;
        text-align:center;
        margin:0 auto;
        @include breakpoint (small only) {
          width:50%;
        }
        li {
          display:table-cell;
          a {
            font-size:2.25rem;
            @include breakpoint (small only) {
              font-size:1.5rem;
            }
          }
        }
      }
    }
    // logo and button
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint (small only) {
        padding-top:1rem;
        padding-bottom:1rem;
      }
      img {
        max-height:55px;
        margin-right:2rem;
      }
      .footer-button {
        font-size:5.5rem;
        @include breakpoint (small only) {
          line-height:1;
          font-size:3rem;
        }
      }
    }
  }
}



/// Footer Content
footer {
  ul {
    list-style: none;
    margin:0;
  }
  .footer-content {
    background-color:$gray;
    color:$light-gray;
    h3 {
      color:$white;
      text-transform: capitalize;
      font-size:1.1rem;
    }
    > div {
      padding-bottom:5rem;
      padding-top:3rem;
      @include breakpoint (small only) {
        padding-top:1rem;
        padding-bottom:3rem;
      }
    }
    .footer-menu-list {
      @include breakpoint (small only) {
        padding-bottom:1rem;
      }
    }
  }
}



/// Footer function
footer {
  .footer-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  .slideInDown,.slideOutUp {
    position:relative;
    z-index:-1;
  }
}



/// Footer Bottom
footer {
  .footer-bottom {
    text-align: center;
    padding-top:1rem;
    padding-bottom:1rem;
    a {
      font-size:.9rem;
      font-weight:400;
      @include breakpoint (medium) {
        font-size:1rem;
      }
    }
  }
}